require('/node_modules/jquery-ui-dist/jquery-ui.css');
require('/node_modules/datatables.net-responsive-dt/css/responsive.dataTables.min.css');
require('/node_modules/select2/dist/css/select2.css');

window.$ = window.jQuery = require('/node_modules/jquery/dist/jquery.js');
require('/node_modules/jquery-form/src/jquery.form.js');
require('/node_modules/jquery-ui-dist/jquery-ui.js');
window.autosize = require('/node_modules/autosize/dist/autosize.js');
require('/node_modules/datatables.net/js/jquery.dataTables.js');
require('/node_modules/datatables.net-responsive/js/dataTables.responsive.js');
require('/node_modules/exif-js/exif.js');
require('/node_modules/noty/js/noty/packaged/jquery.noty.packaged.js');
require('/node_modules/select2/dist/js/select2.full.js');
require('/node_modules/select2/dist/js/i18n/nl.js');
window.io = require('/node_modules/socket.io-client/dist/socket.io.js');

// For datatables report buttons
require('/node_modules/datatables.net-buttons/js/dataTables.buttons.min.js');
require('/node_modules/datatables.net-buttons/js/buttons.colVis.min.js');
require('/node_modules/datatables.net-buttons/js/buttons.html5.min.js');
require('/node_modules/datatables.net-buttons/js/buttons.print.min.js');
